.load {
    display: block; /* Hidden by default */
    position: fixed; 
    z-index: 1; 
    padding-top: 100px; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgba(248,248,251,0.5);
}

.loader {
    position: absolute;
    left: calc(50% - 76px);
    top: calc(50%  - 152px);
    border: 12px solid rgba(246,246,248, 0.1);
    border-radius: 50%;
    border-top: 12px solid #181244;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}
  
/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
